import React from "react";
import { Path, UseFormRegister, ValidationRule } from "react-hook-form";
import { FormData } from "../SellBusinessModal";

interface TextFieldProps {
  field?: "input" | "textarea";
  id: Path<FormData>;
  placeholder?: string;
  type?: string;
  label: string;
  register: UseFormRegister<FormData>;
  required?: string | ValidationRule<boolean>;
  error?: string;
  disabled?: boolean;
}

const TextField = ({
  field = "input",
  id,
  label,
  placeholder,
  type = "text",
  register,
  required,
  error,
  disabled,
}: TextFieldProps) => {
  return (
    <label className="w-full relative" htmlFor={id}>
      <span className="sm:text-sm text-xs block mb-1 font-semibold">
        {label}
        {required ? <span className="ml-1 text-red-500">*</span> : ""}
      </span>
      {field === "input" ? (
        <input
          {...register(id, { required })}
          type={type}
          id={id}
          className={`input-field outline-none ${
            !!error ? "border border-red-500" : "border-gray-300"
          }`}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <textarea
          {...register(id, { required })}
          id={id}
          className={`input-field outline-none ${
            !!error ? "border border-red-500" : "border-gray-300"
          }`}
          placeholder={placeholder}
        />
      )}
      {error && (
        <p className="absolute to-100% sm:text-md text-xs sm:mt-0 -mt-[1px] text-red-500">
          <small>{error}</small>
        </p>
      )}
    </label>
  );
};

export default TextField;
