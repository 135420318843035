import { Parallax } from "react-scroll-parallax";
import IconCircle from "../../images/icon-circle-dark-blue.png";
import React from "react";
import CounterUp from "../common/CounterUp";

const Square = ({
  title: { decimals, end, suffix, prefix },
  subtitle,
}: {
  title: { decimals?: number; end: number; suffix?: string; prefix?: string };
  subtitle: React.ReactNode;
}) => (
  <div className="size-44 xl:size-[235px] flex items-center justify-center flex-col bg-white/40 rounded-2xl text-center text-white">
    <CounterUp
      end={end}
      suffix={suffix}
      classNames="text-5xl xl:text-7xl font-semibold"
      prefix={prefix}
      decimals={decimals}
    />
    <p className="text-center text-sm xl:text-md">{subtitle}</p>
  </div>
);

const FirstSection = () => (
  <div className="parallax bg-center bg-no-repeat py-20 sm:py-32">
    <Parallax
      speed={-10}
      translateX={["-400px", "-400px"]}
      translateY={["100px", "-100px"]}
    >
      <img src={IconCircle} className="absolute" />
    </Parallax>
    <div className="container h-full flex-col relative z-10">
      <div className="flex flex-col gap-5 sm:gap-16 col-span-12 justify-center h-full">
        <div className="grid grid-cols-10 col-span-10 gap-5">
          <div className="col-span-10 sm:col-span-8 lg:col-span-6">
            <h2 className="text-white text-3xl sm:text-5xl lg:text-6xl font-semibold">
              Modernizing small businesses is the challenge of a lifetime
            </h2>
          </div>
        </div>
        <div className="grid grid-cols-3 col-span-12 gap-10">
          <div className="flex justify-center sm:justify-start col-span-3 sm:col-span-1">
            <Square
              title={{
                end: 82,
                suffix: "%",
              }}
              subtitle={
                <>
                  SMBs fail due <br />
                  to cashflow issues
                </>
              }
            />
          </div>
          <div className="flex justify-center col-span-3 sm:col-span-1">
            <Square
              title={{
                end: 56,
                suffix: "%",
              }}
              subtitle={
                <>
                  Owners says digitalization is <br />
                  too expensive (McKinsey)
                </>
              }
            />
          </div>
          <div className="flex justify-center sm:justify-end col-span-3 sm:col-span-1">
            <Square
              title={{ end: 84, suffix: "%" }}
              subtitle={
                <>
                  SMBs struggle to find <br />
                  enough people to interview <br />
                  and hire
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FirstSection;
