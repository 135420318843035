import React from "react";
import { Link } from "gatsby";
import Socials from "../Socials";

const FooterSection = () => {
  const year = new Date().getFullYear();
  return (
    <div className="bg-black py-4 border-[1px] border-x-0 border-b-0 border-t-white/15">
      <div className="container items-center">
        <div className="col-span-12 sm:col-span-4 flex items-start sm:justify-start justify-center">
          <p className="text-white text-sm font-light">@ SKROL {year}</p>
        </div>
        <div className="col-span-12 sm:col-span-2 lg:col-span-4 flex justify-center">
          <Socials />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex justify-center sm:justify-end gap-5">
          <div className="flex md:flex-row flex-cols items-center justify-center md:mt-0 mt-4 gap-5 text-white text-xs font-light">
            <Link
              className="hover:text-gray-400 transition-colors"
              to="/legal-notice"
            >
              Legal notice
            </Link>
            <Link
              className="hover:text-gray-400 transition-colors"
              to="/privacy-policy"
            >
              Privacy policy
            </Link>
            <Link
              className="hover:text-gray-400 transition-colors"
              to="/terms-and-conditions"
            >
              Terms & conditions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
