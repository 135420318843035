import React, {
  createContext,
  useContext,
  useState,
  FC,
  ReactNode,
} from "react";

interface SellBusinessContextProps {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

const SellBusinessContext = createContext<SellBusinessContextProps | undefined>(
  undefined
);

export const useSellBusiness = () => {
  const context = useContext(SellBusinessContext);
  if (!context) {
    throw new Error(
      "useSellBusiness must be used within a SellBusinessProvider"
    );
  }
  return context;
};

interface SellBusinessProviderProps {
  children: ReactNode;
}

export const SellBusinessProvider: FC<SellBusinessProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <SellBusinessContext.Provider value={{ isOpen, handleOpen, handleClose }}>
      {children}
    </SellBusinessContext.Provider>
  );
};
