import React from "react";

const Logo = ({ fill = "black" }) => (
  <svg
    className="transition-all"
    viewBox="0 0 306 77"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M30.1042 76.6187C25.5446 76.6187 21.3941 75.9602 17.6527 74.6412C13.9113 73.3242 10.5989 71.4186 7.71347 68.9243C4.82808 66.432 2.42359 63.4029 0.5 59.8391L15.0347 50.3269C16.8865 52.9649 19.2909 55.084 22.2482 56.6863C25.2034 58.2887 28.2145 59.0908 31.2795 59.0908C33.6321 59.0908 35.394 58.6299 36.5694 57.702C37.7447 56.7781 38.3333 55.6367 38.3333 54.2838C38.3333 53.358 38.0659 52.5378 37.5311 51.8255C36.9964 51.1131 35.9448 50.4187 34.3784 49.7402C32.81 49.0638 30.5312 48.4033 27.538 47.7628C21.2664 46.338 16.2798 44.5402 12.5763 42.3651C8.87082 40.1921 6.23286 37.5562 4.66845 34.4573C3.10004 31.3584 2.31783 27.8145 2.31783 23.8236C2.31783 19.4057 3.45722 15.3989 5.738 11.8012C8.01678 8.20342 11.2773 5.33399 15.5176 3.19689C19.7559 1.05979 24.7963 -0.00976562 30.6409 -0.00976562C37.1959 -0.00976562 42.7891 1.29126 47.4205 3.8913C52.0519 6.49334 55.6137 9.8237 58.108 13.8824L43.5733 24.3564C41.9331 22.1475 39.7421 20.5631 37.0004 19.6013C34.2567 18.6395 31.6387 18.1586 29.1444 18.1586C26.9354 18.1586 25.1715 18.5697 23.8545 19.3878C22.5355 20.2079 21.877 21.3293 21.877 22.7541C21.877 24.1788 22.6073 25.3202 24.068 26.1742C25.5267 27.0303 28.432 27.9562 32.778 28.9519C38.7623 30.3068 43.6611 32.0348 47.4744 34.136C51.2856 36.2392 54.1012 38.8392 55.917 41.9401C57.7349 45.039 58.6428 48.8323 58.6428 53.322C58.6428 57.8118 57.5034 61.8186 55.2226 65.3445C52.9418 68.8724 49.6653 71.6341 45.3911 73.6275C41.1169 75.6209 36.0206 76.6207 30.1082 76.6207L30.1042 76.6187Z" />
    <path d="M124.582 1.91357H100.642L79.4789 28.2632V1.91357H60.1353V74.6968H79.4789V48.8281L99.3594 74.6968H124.155L95.3166 38.3162L124.582 1.91357Z" />
    <path d="M159.959 74.6968H181.12L169.208 50.5142C172.119 48.6585 174.539 46.264 176.471 43.3287C179.071 39.3737 180.372 34.475 180.372 28.6324C180.372 22.7897 179.071 17.891 176.471 13.936C173.869 9.98109 170.379 6.98994 165.999 4.9586C161.617 2.92925 156.754 1.91357 151.41 1.91357H124.478V74.6968H143.822V55.2454H150.379L159.961 74.6968H159.959ZM143.82 20.7245H150.768C154.044 20.7245 156.521 21.4009 158.197 22.7538C159.869 24.1087 160.707 26.0682 160.707 28.6324C160.707 31.1965 159.869 33.2278 158.197 34.5089C156.521 35.7919 154.044 36.4325 150.768 36.4325H143.82V20.7225V20.7245Z" />
    <path d="M306 74.6968H260.043V1.91357H279.389V55.8819L306 55.8839V74.6968Z" />
    <path d="M247.035 40.0983C243.731 36.716 239.776 34.106 235.175 32.2383C230.573 30.4005 225.507 29.4746 219.947 29.4746C214.388 29.4746 209.322 30.4005 204.766 32.2383C200.211 34.106 196.288 36.716 193.013 40.0983C189.739 43.4805 187.206 47.4953 185.431 52.1287C184.984 53.302 184.58 54.4913 184.257 55.7265C183.918 56.9457 183.639 58.2128 183.407 59.4938C182.974 62.0121 182.743 64.652 182.743 67.4017C182.743 69.9958 182.944 72.498 183.329 74.8925H203.607C202.881 72.6996 202.525 70.1973 202.525 67.4017C202.525 64.4205 202.926 61.7646 203.761 59.4479C204.024 58.6757 204.347 57.9494 204.718 57.255C206.171 54.4753 208.224 52.3602 210.864 50.8915C213.49 49.4389 216.517 48.6986 219.945 48.6986C223.374 48.6986 226.494 49.4089 229.134 50.8456C231.774 52.2664 233.83 54.3676 235.328 57.1472C236.827 59.9268 237.583 63.3091 237.583 67.294C237.583 70.1195 237.198 72.6537 236.44 74.8925H256.763C257.148 72.498 257.35 69.9978 257.35 67.4017C257.35 61.8424 256.454 56.7601 254.678 52.1287C252.902 47.4953 250.354 43.4805 247.033 40.0983H247.035Z" />
    <path d="M229.136 6.18424C226.496 7.60499 223.437 8.31536 219.947 8.31536C216.457 8.31536 213.492 7.60499 210.866 6.18424C209.044 5.19651 207.5 3.89948 206.249 2.26123H184.504C184.768 3.18711 185.075 4.11498 185.43 5.01093C187.206 9.64431 189.738 13.6432 193.013 17.0414C196.287 20.4236 200.21 23.0177 204.766 24.8395C209.322 26.6474 214.388 27.5573 219.947 27.5573C225.506 27.5573 230.573 26.6454 235.174 24.8395C239.776 23.0177 243.731 20.4216 247.035 17.0414C250.356 13.6432 252.904 9.64431 254.68 5.01093C255.019 4.11498 255.328 3.1891 255.605 2.26123H233.754C232.486 3.89748 230.958 5.21047 229.136 6.18424Z" />
  </svg>
);

export default Logo;
