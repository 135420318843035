import React from "react";
import IconCircle from "../../images/icon-circle-dark-green.png";
import ImageLaptop from "../../images/image-laptop.png";
import BgIcon from "../../images/bg-icon.png";
import ExperienceImage from "../../images/second-image.png";
import OverlayExperience from "../../images/overlay-experience.png";
import { Parallax } from "react-scroll-parallax";
import Logo from "../icons/Logo";

const SeventhSection = () => (
  <div className="parallax bg-center bg-no-repeat py-10 sm:py-32">
    <Parallax
      speed={-10}
      translateY={["-200px", "200px"]}
      translateX={["100%", "33.3%"]}
    >
      <img src={IconCircle} className="absolute" />
    </Parallax>
    <div className="container h-full relative z-10">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 col-span-12">
        <div
          className="col-span-1 flex flex-col px-9 py-20 lg:py-14 xl:py-16 relative bg-cover bg-center rounded-2xl gap-3 xl:gap-5 overflow-hidden"
          style={{
            backgroundImage: `url(${ImageLaptop})`,
          }}
        >
          <div className="absolute inset-0 bg-black/80"></div>
          <div
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${BgIcon})`,
            }}
          ></div>
          <h3 className="text-3xl xl:text-4xl font-bold relative">
            Fast Growth
          </h3>
          <p className="text-md xl:text-2xl relative leading-tight">
            Our goal is to apply the learnings from scaling high growth tech
            startups to profitable, sustainable and established businesses and
            help them grow fast.
          </p>
          <div className="absolute bottom-3 right-5 opacity-20">
            <div className="w-[90px] xl:w-[128px]">
              <Logo fill="white" />
            </div>
          </div>
        </div>
        <div className="col-span-1 flex flex-col px-9 py-20 lg:py-14 xl:py-16 relative bg-cover bg-center rounded-2xl gap-3 xl:gap-5 overflow-hidden">
          <h3 className="text-3xl xl:text-4xl font-bold">Value Creation</h3>
          <p className="text-md xl:text-2xl relative leading-tight">
            We believe that substantial value creation is possible by equipping
            traditional businesses with the right human and financial capital.
          </p>
          <div className="absolute bottom-3 right-5 opacity-20">
            <div className="w-[90px] xl:w-[128px]">
              <Logo fill="white" />
            </div>
          </div>
        </div>
        <div
          className="col-span-1 flex flex-col px-9 py-20 lg:py-14 xl:py-16 relative bg-cover bg-center rounded-2xl gap-3 xl:gap-5 overflow-hidden"
          style={{
            backgroundImage: `url(${ExperienceImage})`,
          }}
        >
          <div className="absolute inset-y-0 bg-black/80 right-0 w-5/12"></div>
          <div
            className="absolute inset-0 bg-cover"
            style={{
              backgroundImage: `url(${OverlayExperience})`,
            }}
          ></div>
          <h3 className="text-3xl xl:text-4xl font-bold relative">
            Experience
          </h3>
          <p className="text-md xl:text-2xl relative leading-tight">
            Founded by some of Europe’s most successful entrepreneurs who
            created over $300m in ARR, and over $1bn+ in equity value and debt
            raised.
          </p>
          <div className="absolute bottom-3 right-5 opacity-20">
            <div className="w-[90px] xl:w-[128px]">
              <Logo fill="white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SeventhSection;
