import React from "react";
import IconCircle from "../../images/icon-circle-dark-green.png";
import { Parallax } from "react-scroll-parallax";
import CounterUp from "../common/CounterUp";

export const Square = ({
  title: { decimals, end, suffix, prefix },
  subtitle,
}: {
  title: { decimals?: number; end: number; suffix?: string; prefix?: string };
  subtitle: React.ReactNode;
}) => (
  <div className="border-2 sm:border-4 size-36 sm:size-48 xl:size-60 rounded-lg sm:rounded-2xl border-white/40 p-2 sm:p-4">
    <div className="bg-white/40 rounded-lg sm:rounded-2xl size-full flex items-center justify-center flex-col gap-2 text-white py-5 sm:py-0">
      <CounterUp
        end={end}
        suffix={suffix}
        classNames="text-2xl sm:text-4xl xl:text-6xl font-semibold"
        prefix={prefix}
        decimals={decimals}
      />
      <p className="text-sm sm:text-md leading-tight text-center">{subtitle}</p>
    </div>
  </div>
);

const ThirdSection = () => (
  <div className="parallax bg-center bg-no-repeat pb-10 sm:pb-20">
    <Parallax
      speed={-10}
      translateX={["1200px", "1500px"]}
      translateY={["400px", "400px"]}
    >
      <img src={IconCircle} className="absolute" />
    </Parallax>
    <div className="container h-full flex-col relative z-10">
      <div className="col-span-12 flex flex-col gap-3 sm:gap-8 mb-10 sm:mb-20">
        <h2 className="text-white text-left text-2xl sm:text-4xl lg:text-6xl font-regular">
          They're the lifeblood of our{" "}
          <span className="text-gradient-secondary">economy</span>, creating{" "}
          <span className="font-semibold">jobs and opportunities</span> for
          independence and <span className="font-semibold">growth</span>.
        </h2>
        <p className="text-left text-lg sm:text-3xl text-darkPrimary max-w-2xl font-exlight">
          Without them, we lose the heartbeat of our communities. And their
          potential for ongoing progress.
        </p>
      </div>
      <div className="grid grid-cols-4 sm:grid-cols-3 col-span-12 gap-4 sm:gap-12">
        <div className="col-span-2 sm:col-span-1 flex justify-center sm:justify-start">
          <Square
            title={{
              suffix: "trn",
              prefix: "$",
              end: 10,
            }}
            subtitle={
              <>
                Industry growth <br />
                in next 5 years
              </>
            }
          />
        </div>
        <div className="col-span-2 sm:col-span-1 flex justify-center">
          <Square
            title={{ end: 33, suffix: "mn" }}
            subtitle={
              <>
                SMBs fail due <br />
                to cashflow issues
              </>
            }
          />
        </div>
        <div className="col-span-2 sm:col-span-1 flex justify-center sm:justify-end">
          <Square
            title={{
              end: 61.6,
              suffix: "mn",
              decimals: 1,
            }}
            subtitle={
              <>
                People employed <br />
                by small businesses <br />
                in the US
              </>
            }
          />
        </div>
        <div className="col-span-2 sm:col-span-1 flex justify-center sm:justify-start">
          <Square
            title={{
              end: 400,
              suffix: "mn",
            }}
            subtitle={
              <>
                SMBs worldwide, <br />
                99% of all businesses
              </>
            }
          />
        </div>
        <div className="col-span-2 sm:col-span-1 flex justify-center">
          <Square
            title={{
              end: 10,
              suffix: "k",
            }}
            subtitle={
              <>
                Baby boomers <br />
                retiring daily
              </>
            }
          />
        </div>
        <div className="col-span-2 sm:col-span-1 flex justify-center sm:justify-end">
          <Square
            title={{ end: 55, suffix: "+" }}
            subtitle={
              <>
                Years old own <br />
                about 50% of small <br />
                businesses
              </>
            }
          />
        </div>
      </div>
    </div>
  </div>
);

export default ThirdSection;
