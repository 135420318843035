import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";

const ScrollCountUp = ({
  suffix,
  classNames,
  prefix,
  end,
  decimals,
}: {
  suffix?: string;
  classNames: string;
  prefix?: string;
  end: number;
  decimals?: number;
}) => {
  const [hasBeenVisible, setHasBeenVisible] = useState(false); // Estado para controlar se já foi visível
  const countUpRef = useRef<HTMLDivElement | null>(null);

  // Função para observar quando a div entra na tela
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        // Quando o elemento estiver visível pela primeira vez
        if (entry.isIntersecting && !hasBeenVisible) {
          setHasBeenVisible(true); // Define que já foi visível
        }
      },
      { threshold: 0.5 } // 50% da div deve estar visível para acionar
    );

    if (countUpRef.current) {
      observer.observe(countUpRef.current); // Verifica se o ref é válido
    }

    // Cleanup observer quando o componente desmontar
    return () => {
      if (countUpRef.current) {
        observer.unobserve(countUpRef.current); // Verifica se o ref é válido
      }
    };
  }, [hasBeenVisible]); // O efeito roda quando 'hasBeenVisible' muda

  return (
    <div ref={countUpRef}>
      <h3 className={`counter ${classNames}`}>
        {hasBeenVisible && (
          <CountUp
            start={0}
            end={end}
            duration={3}
            prefix={prefix}
            suffix={suffix}
            decimal="."
            decimals={decimals}
          />
        )}
      </h3>
    </div>
  );
};

export default ScrollCountUp;
