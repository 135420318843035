import React from "react";
import SellBusinessModal from "./SellBusinessModal";
import { SellBusinessProvider } from "../contexts/sellBusinessContext";
import { FooterSection } from "./sections";
import Header from "./Header";

const Layout = ({ children }) => (
  <SellBusinessProvider>
    <Header />
    {children}
    <SellBusinessModal />
    <FooterSection />
  </SellBusinessProvider>
);

export default Layout;
