import React from "react";
import GoogleMaps from "../GoogleMaps";
import Background from "../../images/image-contact.png";
import { useSellBusiness } from "../../contexts/sellBusinessContext";
import { onSendToGa } from "../../utils/events-click";

const ContactSection = () => {
  const { handleOpen } = useSellBusiness();

  return (
    <div
      className="bg-cover relative h-screen flex items-center"
      style={{
        backgroundImage: `url(${Background})`,
      }}
    >
      <div className="absolute bg-gradient-to-b from-black to-black/40 inset-0"></div>
      <div className="container py-10 sm:py-20 relative">
        <div className="flex flex-col gap-4 sm:gap-8 col-span-12 sm:col-span-6 lg:col-span-4 relative text-left">
          <h2 className="text-white text-4xl xl:text-5xl font-semibold">
            Ready to talk?
          </h2>
          <p className="text-white text-lg sm:text-2xl">
            A conversation could be the start of a long-term partnership
          </p>
          <div className="flex">
            <span
              className="btn-primary cursor-pointer"
              onClick={() => {
                handleOpen();
                onSendToGa("Let's talk button")
              }}
            >
              Let's talk
            </span>
          </div>
        </div>
        <div className="lg:col-start-9 col-span-12 sm:col-span-6 lg:col-span-4 flex flex-col items-right gap-2 sm:gap-8 sm:text-right relative mt-20 lg:mt-0">
          <h2 className="text-white text-4xl xl:text-5xl font-semibold">
            Contact us
          </h2>
          <p className="text-white text-md sm:text-xl font-thin">
            <strong className="font-bold">SKROL Capital, Inc.</strong>
            <br />
            1101 Brickell Avenue
            <br />
            South Tower, 8th Floor
            <br />
            Miami, FL 33131
          </p>
          {/* <GoogleMaps /> */}
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
