import React from "react";
import LinkedinIcon from "../images/icons/linkedin.svg";
import TwitterIcon from "../images/icons/twitter.svg";
import { Link } from "gatsby";

const Socials = () => {
  return (
    <div className="flex items-center justify-center gap-3">
      <Link to="https://www.linkedin.com/company/skrolhq" target="_blank" className="max-w-4">
        <img src={LinkedinIcon} alt="" className="brightness-0 invert" />
      </Link>
      <Link to="https://twitter.com/skrolhq" target="_blank" className="max-w-3">
        <img src={TwitterIcon} alt="" className="brightness-0 invert" />
      </Link>
    </div>
  );
};

export default Socials;
